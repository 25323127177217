import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";

import api from "@api/index";
import { IApiDataDto, IVenueScripts } from "@interfaces/rest/apiData.dto";
import { IApiDataConfig, IApiDataState, IStyleConfig } from "@interfaces/types/apiData.type";
import { ConfigClientEnum, ClientCompanyNameEnum, VenueNameEnum, IMemberVenueId } from "@interfaces/enums/apiData.enum";
import snStyles from "@theme/sn.json";
import cggStyles from "@theme/cgg.json";

const THEMES = {
  sn: snStyles,
  cgg: cggStyles,
};

const SENTRY_DSN = "https://a9e4fe50f351e078a79a4ca60fc91c12@o4507657321381888.ingest.us.sentry.io/4507854066614273";

const useConfigState = (): IApiDataState => {
  const [config, setConfig] = useState<IApiDataConfig | null>(null);
  const [script, setScript] = useState<IVenueScripts | null>(null);

  const [{ data }, getConfig] = useAxios<IApiDataDto>(
    {
      url: api.config,
      method: "get",
    },
    { manual: true },
  );

  const [{}, getTrackingScript] = useAxios<IVenueScripts>(
    {
      method: "get",
    },
    { manual: true },
  );

  const fetchTrackingScripts = async (URI: string, client: ConfigClientEnum) => {
    try {
      const response = await getTrackingScript({
        url: URI + api.getTrackingScript(IMemberVenueId[client]),
      });
      setScript(response.data);
    } catch (error) {
      console.error("Error while fetching tracking scripts!", error);
      Sentry.captureEvent(error as Sentry.Event);
    }
  };

  useEffect(() => {
    getConfig()
      .then((response) => {
        const clientDomain = window.location.hostname.split(".")[0] as ConfigClientEnum;
        const beforeUrl: ConfigClientEnum = Object.keys(ConfigClientEnum).includes(clientDomain)
          ? clientDomain
          : ConfigClientEnum.sn;

        const client: ConfigClientEnum = window.location.hostname === "localhost" ? ConfigClientEnum.sn : beforeUrl;

        const newConfig: IApiDataConfig = {
          API_URI: response.data.REACT_APP_TICKET_URL,
          client,
          companyName: ClientCompanyNameEnum[client],
          venueName: VenueNameEnum[client],
          azureInsightsKey: response.data.REACT_APP_INSIGHTS_KEY,
          gaMeasurementId: response.data.REACT_APP_GA[client],
          cartToken: uuidv4(),
        };
        setConfig(newConfig);
      })
      .catch((err) => {
        console.error("Error while fetching config!", err);
        Sentry.captureEvent(err);
      });
  }, []);

  useEffect(() => {
    if (config?.client) {
      fetchTrackingScripts(config?.API_URI, config.client);
      const currentThemeConfig: IStyleConfig = THEMES[config.client];
      type StyleConfigPropsArray = Array<keyof IStyleConfig>;
      const propsArray: StyleConfigPropsArray = Object.keys(currentThemeConfig) as StyleConfigPropsArray;

      const setStyles = () => {
        const sheet: CSSStyleSheet = document.styleSheets[0];

        if (sheet && sheet?.insertRule) {
          propsArray.forEach((keyName: keyof IStyleConfig) => {
            sheet.insertRule(`:root{${keyName}:${currentThemeConfig[keyName]}}`);
          });
        }
      };

      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", () => {
          setStyles();
        });
      } else {
        setStyles();
      }
    }
  }, [config?.client]);

  useEffect(() => {
    if (data?.REACT_APP_SENTRY_ENV) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: true,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api\.siaticketing\.com\/?/,
          /^https:\/\/api\.uat\.siaticketing\.com\/?/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        autoSessionTracking: true,
        environment: data.REACT_APP_SENTRY_ENV,
        denyUrls: [/analytics\.google\.com/, /google-analytics\.com/, /dc\.services\.visualstudio\.com/],
      });
    }
  }, [data?.REACT_APP_SENTRY_ENV]);

  return {
    config,
    script,
  };
};

export default useConfigState;
