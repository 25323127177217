import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useProductsList from "@pages/productsPreview/provider/useProductsList";
import useCartState from "@pages/cart/provider/useCart";
import useProductState from "./useProductState";

const ProductContext = createContext<any | undefined>(undefined);

const ProductProvider: FC<PropsWithChildren> = ({ children }) => {
  const { productsList, handleDirectProductPageOpen } = useProductsList();
  const { products } = useCartState();

  const {
    isLoading,
    product,
    productDates,
    fetchProductDates,
    ticketData,
    productInventory,
    step,
    handleAddTicketData,
    ticketTotal,
    isCityPassExchange,
    barcodes,
    addCitypassBarcodeInstance,
    handleCitypassBarcodeChange,
    cityPassProduct,
    isExchangeOrdering,
    handleExchangeOrder,
    removeCitypassBarcode,
    exchangeOrderData,
  } = useProductState(productsList, products, handleDirectProductPageOpen);

  const value = useMemo(
    () => ({
      isLoading,
      product,
      productDates,
      fetchProductDates,
      ticketData,
      productInventory,
      step,
      handleAddTicketData,
      ticketTotal,
      isCityPassExchange,
      barcodes,
      addCitypassBarcodeInstance,
      handleCitypassBarcodeChange,
      cityPassProduct,
      isExchangeOrdering,
      handleExchangeOrder,
      removeCitypassBarcode,
      exchangeOrderData,
    }),
    [
      isLoading,
      product,
      productDates,
      ticketData,
      productInventory,
      step,
      ticketTotal,
      isCityPassExchange,
      barcodes,
      cityPassProduct,
      isExchangeOrdering,
      exchangeOrderData,
    ],
  );

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export { ProductProvider, ProductContext };
