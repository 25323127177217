import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useSelfServiceState from "./useSelfServiceState";

const SelfServiceContext = createContext<any | undefined>(undefined);

const SelfServiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    isLoading,
    orderData,
    getOderData,
    resetOrderData,
    orderNotFoundError,
    resetOrderNotFoundModal,
    cancelBooking,
    cancellationResult,
    resetCancellationResult,
  } = useSelfServiceState();

  const value = useMemo(
    () => ({
      isLoading,
      orderData,
      getOderData,
      resetOrderData,
      orderNotFoundError,
      resetOrderNotFoundModal,
      cancelBooking,
      cancellationResult,
      resetCancellationResult,
    }),
    [isLoading, orderData, orderNotFoundError, cancellationResult],
  );

  return <SelfServiceContext.Provider value={value}>{children}</SelfServiceContext.Provider>;
};

export { SelfServiceProvider, SelfServiceContext };
