import React, { FC, PropsWithChildren } from "react";
import axios, { InternalAxiosRequestConfig } from "axios";
import { configure } from "axios-hooks";
import axiosRetry from "axios-retry";

import { ISalesTerminalTypeId, ConfigClientEnum, IMemberVenueId } from "@interfaces/enums/apiData.enum";
import { IAppKey } from "@interfaces/types/apiData.type";

import useConfig from "./providers/configProvider/useConfig";

const retryRules = { retries: 2, retryCondition: () => true, retryDelay: () => 1000 };

const AxiosClient: FC<PropsWithChildren> = ({ children }) => {
  const { config } = useConfig();

  const data: IAppKey = {
    MemberVenueId: IMemberVenueId[config?.client || ConfigClientEnum.sn],
    SalesTerminalTypeId: ISalesTerminalTypeId[config?.client || ConfigClientEnum.sn],
    LocalTimeZone: "Pacific Standard Time",
  };

  const appKey: string = btoa(JSON.stringify(data));

  const instance = axios.create({
    baseURL: config?.API_URI,
  });

  instance.interceptors.request.use((axiosConfig: InternalAxiosRequestConfig) => {
    axiosConfig.headers = axiosConfig.headers || {};
    axiosConfig.headers.appKey = appKey;

    return axiosConfig;
  });

  axiosRetry(axios, retryRules);
  axiosRetry(instance, retryRules);
  configure({ axios: instance, cache: false });

  return <>{children}</>;
};

export default AxiosClient;
