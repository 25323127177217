import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useProductsListState from "./useProductsListState";

const ProductsListContext = createContext<any | undefined>(undefined);

const ProductsListProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    isLoading,
    productsList,
    checkoutData,
    getProductsByPromo,
    isPromoInvalid,
    resetPromoError,
    calculatePruductCategoryById,
    handleDirectProductPageOpen,
  } = useProductsListState();

  const value = useMemo(
    () => ({
      isLoading,
      productsList,
      checkoutData,
      getProductsByPromo,
      isPromoInvalid,
      resetPromoError,
      calculatePruductCategoryById,
      handleDirectProductPageOpen,
    }),
    [isLoading, productsList, checkoutData, isPromoInvalid],
  );

  return <ProductsListContext.Provider value={value}>{children}</ProductsListContext.Provider>;
};

export { ProductsListProvider, ProductsListContext };
