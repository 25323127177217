import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useConfigState from "./useConfigState";

const ConfigContext = createContext<any | undefined>(undefined);

const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useConfigState();

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export { ConfigProvider, ConfigContext };
