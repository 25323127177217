import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./normalize.scss";
import "./theme/base.scss";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found. Ensure you have a div with id='root' in your index.html.");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
