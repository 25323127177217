import React, { FC, PropsWithChildren, createContext, useMemo } from "react";

import useViewTicketsState from "./useViewTicketsState";

const ViewTicketsContext = createContext<any | undefined>(undefined);

const ViewTicketsProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    isLoading,
    ticketsData,
    step,
    scrollCarousel,
    ticketTermsDataToShow,
    handleShowMoreInfoChange,
    viewError,
    handleResetError,
  } = useViewTicketsState();

  const value = useMemo(
    () => ({
      isLoading,
      ticketsData,
      step,
      scrollCarousel,
      ticketTermsDataToShow,
      handleShowMoreInfoChange,
      viewError,
      handleResetError,
    }),
    [isLoading, ticketsData, step, ticketTermsDataToShow, viewError],
  );

  return <ViewTicketsContext.Provider value={value}>{children}</ViewTicketsContext.Provider>;
};

export { ViewTicketsProvider, ViewTicketsContext };
